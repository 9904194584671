import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import styles from "./styles.module.sass"

import logo from "../../img/PicaLogo.png"
import mobileLogo from "../../img/MobileLogo.png"
import hamburger from "../../img/hamburger.png"
import cross from "../../img/cross.png"

class PicaNavBar extends Component {
  constructor(props) {
    super(props)
    this.toggleNav = this.toggleNav.bind(this)
    this.closeNav = this.closeNav.bind(this)
    this.openNav = this.openNav.bind(this)
    this.state = {
      isOpen: false,
      mobile: false,
    }
  }

  toggleNav() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  openNav() {
    this.setState({
      isOpen: true,
    })
  }

  closeNav() {
    this.setState({
      isOpen: false,
    })
  }

  updateDimensions(e) {
    if (window.innerWidth > 767) {
      if (this.state.mobile) {
        this.setState({
          mobile: false,
        })
      }
    } else {
      if (!this.state.mobile) {
        this.setState({
          mobile: true,
        })
      }
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  render() {
    return (
      <div className={styles.PicaNavBar}>
        <div
          className={styles.mainMenu}
          style={{ display: window.innerWidth >= 767 ? "flex" : "none" }}
        >
          <NavLink to="/">
            <img src={logo} alt="logo" id={styles.menuLogo} />
          </NavLink>
          <div className={styles.menuOptions}>
            <NavLink onClick={this.closeNav} to="/events">
              What's On
            </NavLink>
            <NavLink onClick={this.closeNav} to="/programs">
              Our Work
            </NavLink>
            <NavLink onClick={this.closeNav} to="/about">
              About
            </NavLink>
            <NavLink onClick={this.closeNav} to="/artistsandarchive">
              Artists Archive
            </NavLink>
            <NavLink onClick={this.closeNav} to="/blog">
              Blog
            </NavLink>
            <NavLink onClick={this.closeNav} to="/precipice-fund">
              Precipice Fund
            </NavLink>
            <NavLink onClick={this.closeNav} to="/support">
              Support
            </NavLink>
            <NavLink onClick={this.closeNav} to="/basket">
              Cart
            </NavLink>
            <NavLink onClick={this.closeNav} to="/account">
              Account
            </NavLink>
          </div>
        </div>
        <div
          className={styles.mobileNav}
          style={{ display: window.innerWidth < 767 ? "flex" : "none" }}
        >
          <div className={styles.mobileBar} style={{ zIndex: "2" }}>
            <NavLink onClick={this.closeNav} to="/">
              <img src={mobileLogo} alt="mobilelogo" id={styles.mobileLogo} />
            </NavLink>
            <img
              onClick={this.toggleNav}
              src={this.state.isOpen ? cross : hamburger}
              alt="menuicon"
              id={styles.menuIcon}
            />
          </div>
          <div
            className={
              this.state.isOpen ? styles.mobileMenuOpen : styles.mobileMenu
            }
            style={{ zIndex: "1" }}
          >
            <div className={styles.mobileMenuOptions}>
              <NavLink onClick={this.closeNav} to="/">
                Home
              </NavLink>
              <NavLink onClick={this.closeNav} to="/events">
                What's On
              </NavLink>
              <NavLink onClick={this.closeNav} to="/programs">
                Our Work
              </NavLink>
              <NavLink onClick={this.closeNav} to="/about">
                About
              </NavLink>
              <NavLink onClick={this.closeNav} to="/artistsandarchive">
                Artists Archive
              </NavLink>
              <NavLink onClick={this.closeNav} to="/blog">
                Blog
              </NavLink>
              <NavLink onClick={this.closeNav} to="/precipice-fund">
                Precipice Fund
              </NavLink>
              <NavLink onClick={this.closeNav} to="/support">
                Support
              </NavLink>
              <NavLink onClick={this.closeNav} to="/cart">
                Cart
              </NavLink>
              <NavLink onClick={this.closeNav} to="/account">
                Account
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PicaNavBar
