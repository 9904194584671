import { fundOptions } from "../../utilities"

export const AMOUNT = "AMOUNT"
export const RECOGNITION_NAME = "RECOGNITION NAME"
export const TOGGLE_CHECKBOX = "TOGGLE CHECKBOX"
export const IN_HONOR_OF_TITLE = "IN HONOR OF"
export const IN_HONOR_OF_VALUE = "IN HONOR OF VALUE"
export const IS_ANONYMOUS = "IS ANONYMOUS"
export const FUND_TYPE = "FUND TYPE"

export const initialDonationState = {
  amount: 25,
  recognitionName: "",
  isTribute: false,
  tributeType: "In honor of",
  tributeName: null,
  isAnonymous: false,
  isDisable: false,
  fundType: fundOptions.general,
}

const checkDisabled = (amount) => {
  if (parseInt(amount) > 0) {
    return false
  }
  return true
}

const donationReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case AMOUNT:
      return {
        ...state,
        amount: payload,
        isDisable: checkDisabled(payload),
      }
    case RECOGNITION_NAME:
      return {
        ...state,
        recognitionName: payload,
      }
    case TOGGLE_CHECKBOX:
      return { ...state, isTribute: !state.isTribute }
    case IN_HONOR_OF_TITLE:
      return { ...state, tributeType: payload }
    case IN_HONOR_OF_VALUE:
      return { ...state, tributeName: payload }
    case IS_ANONYMOUS:
      return {
        ...state,
        isAnonymous: payload,
      }
    case FUND_TYPE:
      return {
        ...state,
        fundType: payload,
      }
    default:
      return state
  }
}

export default donationReducer
